/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        // Start Swipper

        var swiper = new Swiper('.swiper-container', {
          slidesPerView: 1,
          spaceBetween: 30,
          loop: true,
          centeredSlides: true,
          speed: 2000,
          navigation: {
            nextEl: '.btn-next',
            prevEl: '.btn-prev',
          },
        });

        var swiper = new Swiper('.swiper-mainmain', {
          slidesPerView: 1,
          spaceBetween: 30,
          loop: true,
          centeredSlides: true,
          speed: 2000,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
        });

        var mySwiper = new Swiper('.swiper-testimonials', {
          slidesPerView: 1,
          loop: true,
          speed: 2000,
          spaceBetween: 100,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
        });
        var mySwiper = new Swiper('.swiper-gallery', {
          slidesPerView: 3,
          loop: true,
          speed: 2000,
          spaceBetween: 0,
          autoplay: true,
          autoplayStopOnLast: false, 
          autoHeight: true,
          pagination: {
            el: '.swiper-pagination-gallery',
            type: 'bullets',
            clickable: true,
          },
        });

        var swiper = new Swiper('.swiper-container-prac', {
          slidesPerView: 1,
          spaceBetween: 30,
          centeredSlides: true,
          loop: true,
          speed: 2000,
          navigation: {
            nextEl: '.btn-next',
            prevEl: '.btn-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
        });

        var swiper = new Swiper('.swiper-practitioner', {
          speed: 2000,
          navigation: {
            nextEl: '.btn-next',
            prevEl: '.btn-prev',
          },
          pagination: {
            el: '.swiper-pagination-gallery',
            type: 'bullets',
            clickable: true,
          },
        });

        if ($('.filtr-container').length > 0){
         var filterizd = $('.filtr-container').filterizr({
         });
       }

       
      // $( ".filtr_item" ).click(function() {
      //   $( this ).toggleClass( "active" );
      //   $(this).siblings(".filtr_item").removeClass("active");
      //   console.log("clicked");
      // });

      // $( ".filtr_item" ).click(function() {
      //   $( this ).toggleClass( "active" );
      //   $(".tab-pane .filtr_item").removeClass("active");
      //   console.log("clicked");
      // });

      var currentHeight = 0;
      $(window).load(function() {
        currentHeight = $('.calc_anchor').outerHeight();
        $('.swiper-pagination-gallery').css({'top': currentHeight + 50 });
      });

      $(document).on('click', 'a[href^="#"]', function(e) {
        var id = $(this).attr('href');
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }
        e.preventDefault();
        var pos = $id.offset().top;
        $('body, html').animate({scrollTop: pos});
      });

      var headerHeight = $('.stick_me').height() + 'px';
      $('.wrap').css('padding-top', headerHeight);

      $('.nav-link').addClass('trig_in');

    }
  },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
